.about__thumb.m-img img {
  border-radius: 20px;
}

.services-icon {
  font-size: 43px;
  transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
}

.services__icon {
  transform: translate3d(0, 0, 0);
}

.services__icon:hover {
  -webkit-transform: translate3d(0,-10px,0);
  -moz-transform: translate3d(0,-10px,0);
  -ms-transform: translate3d(0,-10px,0);
  -o-transform: translate3d(0,-10px,0);
  transform: translate3d(0,-10px,0);
}

.footer__limit-width {
  max-width: 1000px;
  margin: 0 auto;
}

.footer__social ul li a:hover {
  background: #00a3d4;
}

.header-circle {
  height: 500px;
}

@media only screen and (max-width: 1199px) {
  .header-circle {
    display: none;
  }
}

.zi-100 {
  z-index: 100;
}

.page__title-2 {
  height: 35vh;
  min-height: 35vh;
}

.staffing__details-img img {
  border-radius: 30px 0 0 30px;
}

.l-radius-30 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.p-50 { 
  padding: 50px;
}

.services__nav .nav-item {
  margin-right: 2rem;
}

.about-img img {
  border-radius: 10px;
}

.black-bg-2 {
  background: #304b6a;
}

.capabilities-paragraph p {
  list-style: inherit;
  margin-left: 22px;
  margin-bottom: 25px;
  font-size: 22px;
  color: #d1cfdd;
  font-family: "Frank Ruhl Libre", serif;
  font-weight: 500;
}

.team-details-paragraph p {
  font-size: 18px;
}

.circle-2 {
  opacity: 55%;
}

.services__link a {
  cursor: pointer;
}

.services__link a:hover {
  color: #39a4d5 !important;
}

.img-radius-about img {
  border-radius: 10px;
}

.contact-button {
  color: #00a3d4;
  text-transform: unset !important;
}

.contact-button:hover {
  color: #fff;
}

.consulting .w-img {
  display: flex;
}

.consulting .w-img img {
  max-width: 100%;
  margin: 0 auto;
  width: unset;
  max-height: 470px;
}

.mobile-sidebar-button {
  margin: 0 auto;
  margin-top: 2rem;
}

.menu-services>a {
  opacity: 1 !important;
}

.contact__help-item {
  padding: 25px 50px !important;
}

.contact__shape img.shape {
  top: 13%;
  left: 38%;
  opacity: 0.15;
  height: 338px;
}

.consulting-menu-fixed {
  position: fixed;
  top: 131px;
}

@media only screen and (max-width: 990px) {
  .consulting-menu-fixed {
    display: none;
  }
}

@media only screen and (max-width: 990px) {

    .slick-arrow.slick-next, .slick-arrow.slick-prev {
      display: none !important;
    }

  .expart__area.pt-150 {
    padding-top: 50px;
  }

  .expart__area .nav-pills li {
    width: 100%;
  }

  .contact__shape {
    display: none;
  }

  .contact__map {
    height: unset;
  }

  .contact__wrapper  {
    opacity: 1 !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
    transform: unset !important;
  }
}

.section-testimonials {
  background: url(../../assets/img/bg/wave-bg-3.png) no-repeat top right !important;
}

.section-testimonials.gradient-bg {
  // background-image: linear-gradient(40deg, #2b4b69 0%, #00bbf3 100%);
  background: #fff;
  max-height: 1529px;
}

.img-contact {
  height: 70px;
}

.section-testimonials .testimonial__info {
  padding-bottom: 20px;
}

.section-testimonials .testimonial__info h4 {
  font-size: 26px;
}

.section-testimonials .testimonial__info span {
  font-size: 17px;
  font-weight: 600;
}
.section-testimonials .testimonial__item .testimonial__content {
  padding-bottom: 20px;
}

.section-testimonials .container {
  padding-top: 290px;
}

.section-testimonials .slick-dots li {
  border: 2px solid rgba(176, 176, 188, 0.4);
}

.section-testimonials .slick-dots li.slick-active{
  background: #b0b0bc;
  border-color: #b0b0bc;
}

.section-testimonials .slick-prev:before, .slick-next:before {
  color: #b0b0bc;
}

.section-testimonials .slick-next {
  right: -30px;
  color: 2px solid rgba(176, 176, 188, 0.4);
}

.section-testimonials .slick-prev {
  left: -35px;
  color: 2px solid rgba(176, 176, 188, 0.4);
}

.privacy p {
  margin-bottom: 0px;
  line-height: 27px;
}

.privacy b.subtitle  {
  padding-top: 16px;
}

.achievement__content.privacy {
  display: inline-grid;
}

.second-privacy {
  margin-top: 5rem;
}

.capabilities-text {
  font-family: "Lato", sans-serif !important;
  color: #fff;
  font-size: 16px !important;
}

.capabilities-paragraph.capabilities-text p {
  font-size: 16px;
}

.policy-padding {
  padding: 15px 0px 35px;
}

.blog__text a {
  color: #00a3d4;
  font-weight: 600;
}

.blog__text a:hover {
  color: #006787;
  font-weight: 600;
}

.blog-image {
  width: 100px;
  height: 100px;
}